import './bootstrap.js'; // import the bootstrap js file
// import '../css/app.css';
import '../scss/app.scss'; // import the application styles for build

import { createApp, h } from 'vue';
import { createInertiaApp, usePage } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import {ZiggyVue} from 'ziggy-js';

import Swal from 'sweetalert2';
import AOS from 'aos';
// import 'aos/dist/aos.css';

window.Swal = Swal.mixin({
	// iconColor: '#0165B1',
});
window.Toast = Swal.mixin({
	// iconColor: '#0165B1',
	position: 'top-end',
	showConfirmButton: false,
	toast: true,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer);
		toast.addEventListener('mouseleave', Swal.resumeTimer);
	},
});

createInertiaApp({
	title: title => `${ title } | K & C Wedding`,
	resolve: (name) => resolvePageComponent(
			`./Pages/${ name }.vue`,
			import.meta.glob('./Pages/**/*.vue'),
	),
	setup({ el, App, props, plugin }) {
		const myApp = createApp({ render: () => h(App, props) })
		.use(plugin)
		.use(ZiggyVue)
		.mixin({
			methods: { route }
		});

		myApp.config.globalProperties.$route = route;

		AOS.init();
		myApp.mount(el)
	},
	progress: {
		color: '#D9B8D9'
	}
})